import React from 'react';
import useMetaMask from '../wallet/hook';
import { ToastContainer } from "react-toastify";
import Countdown, { zeroPad } from "react-countdown";

function ConnectWallet(props) {
    const { connect, disconnect, isActive, account } = useMetaMask();

    const renderer = ({ hours, minutes, seconds, completed }) => {
        // if (completed) {
        //   // Render a completed state
        //   return <Completionist />;
        // } else {
        // Render a countdown
        return <>{zeroPad(hours)}:{zeroPad(minutes)}</>;
        // }
    };
    return (
        <div style={{
            background: 'url(images/MVRS_NFT_MINT_BG.png) no-repeat center top', backgroundSize: 'cover', position: "absolute",
            width: '100%', height: '100%'
        }}>
            <div className="connect-wallete-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <div className="wallete">
                                <div className="logo-section">
                                    <img src="images/logo.png" />
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                                        <div className="sales">
                                            <div className="title">
                                                <h4>16:00 UTC</h4>
                                                <h2>PRIVATE SALE</h2>
                                            </div>
                                            <div className="box">
                                                <h1><Countdown
                                                    date={1643558400000}
                                                    zeroPadTime={2}
                                                    renderer={renderer}
                                                /></h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                                        <div className="salesleft">
                                            <div className="title1">
                                                <h4>18:00 UTC</h4>
                                                <h2>PUBLIC SALE</h2>
                                            </div>
                                            <div className="box1">
                                                <h1><Countdown
                                                    date={1643565600000}
                                                    zeroPadTime={2}
                                                    renderer={renderer}
                                                /></h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                        {!props.isWhitelisted && isActive && props.isUserCheked ? (<p className='text-danger text-center'>You are not whitelisted, please wait for public sale</p>) : ('')}
                                        {!props.publicsaleIsActive && !props.preSaleIsActive && props.isUserCheked && isActive ? (<p className='text-danger text-center'>Sale in not active yet</p>) : ('')}
                                        {isActive && !props.isUserCheked ? (<p className='text-success text-center'>Connecting...</p>) : ('')}
                                        {isActive ? (<div className="sr-btn-wrap">
                                            <button onClick={() => disconnect()} className="sr-btn">
                                                {account
                                                    .slice(0, 6)
                                                    .concat(`...${account.slice(-4)}`)}
                                            </button>
                                        </div>) : (<div className="sr-btn-wrap">
                                            <button onClick={() => connect()} className="sr-btn">Connect wallet</button>
                                        </div>)}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ConnectWallet;
