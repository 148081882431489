import "react-toastify/dist/ReactToastify.css";
import React, { Suspense, useState, useEffect } from "react";
import { Route, Switch } from 'react-router-dom';

import ConnectWallet from './pages/ConnectWallet';
import mainContaint from './pages/MainContaint';

import nftABI from "./abi/nftABI.json";
import useMetaMask from './wallet/hook';

function App() {

  const { isActive, account, library } = useMetaMask();
  var contractAddress = '0x36DD1D01D81844A524F3Ed901014345C28E13741';
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [publicsaleIsActive, setPublicsaleIsActive] = useState(false);
  const [preSaleIsActive, setPreSaleIsActive] = useState(false);
  const [isUserCheked, setIsUserCheked] = useState(false);

  const getUserDetails = async () => {

    var contract = new library.eth.Contract(nftABI, contractAddress);

    var isWhitelisted = await contract.methods.isWhitelisted(account).call();
    var publicsaleIsActive = await contract.methods.publicsaleIsActive.call().call();
    var preSaleIsActive = await contract.methods.preSaleIsActive.call().call();

    setIsWhitelisted(isWhitelisted);
    setPreSaleIsActive(preSaleIsActive)
    setPublicsaleIsActive(publicsaleIsActive)
    setIsUserCheked(true);

  }

  useEffect(() => {
    if (isActive) {
      getUserDetails();
    }
    // getStackerInfo();
  }, [isActive, account]);

  let routes = (
    <Switch>
      <Route path="/" ><ConnectWallet isWhitelisted={isWhitelisted} preSaleIsActive={preSaleIsActive} publicsaleIsActive={publicsaleIsActive} isUserCheked={isUserCheked} /></Route>
    </Switch>
  );

  if (preSaleIsActive) {
    if (isWhitelisted) {
      routes = (
        <Switch>
          <Route path="/" component={mainContaint} />
        </Switch>
      );
    } else {
      routes = (
        <Switch>
          <Route path="/" ><ConnectWallet isWhitelisted={isWhitelisted} preSaleIsActive={preSaleIsActive} publicsaleIsActive={publicsaleIsActive} isUserCheked={isUserCheked} /></Route>
        </Switch>
      );
    }
  } else {
    if (publicsaleIsActive) {
      routes = (
        <Switch>
          <Route path="/" component={mainContaint} />
        </Switch>
      );
    } else {
      routes = (
        <Switch>
          <Route path="/" ><ConnectWallet isWhitelisted={isWhitelisted} preSaleIsActive={preSaleIsActive} publicsaleIsActive={publicsaleIsActive} isUserCheked={isUserCheked} /></Route>
        </Switch>
      );
    }

  }

  return <Suspense fallback={""}>{routes}</Suspense>;
}

export default App;
