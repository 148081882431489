import React from 'react';

import { ToastContainer } from "react-toastify";
import FifthSection from '../component/FifthSection';
import ForthSection from '../component/ForthSection';
import SixthSection from '../component/SixthSection';
import ThirdSection from '../component/ThirdSection';
import FirstSection from '../component/FirstSection';

function MainContaint() {

    return (
        <div>
            <FirstSection />
            <div className="second-section">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <ThirdSection />
                            <ForthSection />
                            <FifthSection />
                            <SixthSection />
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContaint;
