import React, { useState, useEffect } from "react";

import axios from "axios";
import nftABI from "../abi/nftABI.json";
import useMetaMask from '../wallet/hook';

import { toast } from "react-toastify";

function FifthSection() {
    var contractAddress = '0x36DD1D01D81844A524F3Ed901014345C28E13741';
    const { isActive, account, library } = useMetaMask();
    const [nftItemRecord, setNftItemRecord] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const notify = (isError, msg) => {
        if (isError) {
            toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.success(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const getnftItemRecord = async () => {

        var contract = new library.eth.Contract(nftABI, contractAddress);
        var nftItemRecord = [];
        for (var i = 17; i <= 26; i++) {
            var data = await contract.methods.nftItemRecord(i).call();
            data.index = i;
            nftItemRecord.push(data);
        }
        setNftItemRecord(nftItemRecord);
    }

    const mint = async (nftItemRecord) => {
        setIsLoading(true);
        const pinataApiKey = 'e10d55f87e6aef238aee';
        const pinataApiSecret = '7df49177ec001d4ab47ff96418fc18be1603f2759f5c4e5181b92fe814f3d14f';
        const pinataEndpoint = 'https://api.pinata.cloud/pinning/pinJSONToIPFS';
        var mintedCopies = parseInt(nftItemRecord.mintedCopies) + 1;
        var form_data = {
            pinataMetadata: {
                name: nftItemRecord.name + '_' + mintedCopies + ".json",
            },
            pinataContent: {
                "name": nftItemRecord.name + " #" + mintedCopies,
                "image": `https://gateway.pinata.cloud/ipfs/QmVTR8K2Vups6McpN9tdwj5nP4CsCPNAxCeXXMmt8w5XFV/${nftItemRecord.index}.png`,
                "attributes": [
                    {
                        "trait_type": "name",
                        "value": nftItemRecord.name + " #" + mintedCopies
                    },
                    {
                        "trait_type": "rarity",
                        "value": nftItemRecord.rarity
                    }
                ]
            },
        };
        const request = {
            method: "post",
            url: pinataEndpoint,
            maxContentLength: "Infinity",
            headers: {
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataApiSecret
            },
            data: form_data,
        };
        const response = await axios(request);
        var tokenURI = `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;

        var contract = new library.eth.Contract(nftABI, contractAddress);
        try {
            await contract.methods
                .mintTokens(nftItemRecord.index, tokenURI)
                .send({ from: account, value: nftItemRecord.mintPrice })
                .then((result, error) => {
                    getnftItemRecord();
                    setIsLoading(false);
                    notify(false, "Minted Successfully, Now check on opensea");
                });
        } catch (e) {
            setIsLoading(false);
            notify(true, "something went wrong,pls try again");
        }

    }

    useEffect(() => {
        if (isActive) {
            getnftItemRecord();
        }
    }, [isActive, account]);

    return (
        <div>
            <div className="panel">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                    href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <div className="rare">
                        <div className="main-title2">
                            <h1>MVRS rare</h1>
                        </div>
                    </div>
                </a>
                <div id="collapseThree" className="panel-collapse collapse in" role="tabpanel"
                    aria-labelledby="headingOne">
                    <div className="fifth-section-rare mb-2" id="rare">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                <div className="rare-background">
                                    <ul>
                                        {nftItemRecord.map((row, index) => {
                                            return (<li key={index}>
                                                <div className="bounde-box">
                                                    <div className='d-flex' style={{ width: '100%' }}>
                                                        <div className='text-left'>{row.name}</div>
                                                        <div className="text">{row.mintedCopies}/{row.totalCopies}</div></div>
                                                    <div className="img-section">
                                                        <img src={`images/NFT/MVRS_ASSETS/${row.index}.png`}
                                                            alt={row.name} />
                                                    </div>
                                                    <div className="count-blue">{parseFloat(row.mintPrice) / 10 ** 18} eth</div>
                                                </div>
                                                {parseInt(row.mintedCopies) === parseInt(row.totalCopies) ?
                                                    (<div className="sr-btn-wrap">
                                                        <button type="button" className="sr-btn">Sold</button>
                                                    </div>) : (<div className="sr-btn-wrap">
                                                        <button type="button" onClick={() => mint(row)} disabled={isLoading} className="sr-btn">{isLoading ? 'Loading..' : 'mint'}</button>
                                                    </div>)}
                                            </li>)
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FifthSection;
