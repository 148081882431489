import React, { useState, useEffect } from "react";

import axios from "axios";
import nftABI from "../abi/nftABI.json";
import useMetaMask from '../wallet/hook';

import { toast } from "react-toastify";

function FirstSection() {

    var contractAddress = '0x36DD1D01D81844A524F3Ed901014345C28E13741';
    const { isActive, connect, disconnect, account, library } = useMetaMask();
    const [nftItemRecord, setNftItemRecord] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const notify = (isError, msg) => {
        if (isError) {
            toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.success(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const getnftItemRecord = async () => {

        var contract = new library.eth.Contract(nftABI, contractAddress);
        var nftItemRecord = await contract.methods.nftItemRecord(1).call();
        setNftItemRecord(nftItemRecord);
    }

    const mint = async () => {
        setIsLoading(true);
        const pinataApiKey = 'e10d55f87e6aef238aee';
        const pinataApiSecret = '7df49177ec001d4ab47ff96418fc18be1603f2759f5c4e5181b92fe814f3d14f';
        const pinataEndpoint = 'https://api.pinata.cloud/pinning/pinJSONToIPFS';
        var form_data = {
            pinataMetadata: {
                name: nftItemRecord.name + ".json",
            },
            pinataContent: {
                "name": nftItemRecord.name,
                "image": "https://gateway.pinata.cloud/ipfs/QmVTR8K2Vups6McpN9tdwj5nP4CsCPNAxCeXXMmt8w5XFV/1.gif",
                "attributes": [
                    {
                        "trait_type": "name",
                        "value": nftItemRecord.name
                    },
                    {
                        "trait_type": "rarity",
                        "value": nftItemRecord.rarity
                    }
                ]
            },
        };
        const request = {
            method: "post",
            url: pinataEndpoint,
            maxContentLength: "Infinity",
            headers: {
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataApiSecret
            },
            data: form_data,
        };
        const response = await axios(request);
        var tokenURI = `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;

        var contract = new library.eth.Contract(nftABI, contractAddress);
        try {
            await contract.methods
                .mintTokens(1, tokenURI)
                .send({ from: account, value: nftItemRecord.mintPrice })
                .then((result, error) => {
                    getnftItemRecord();
                    setIsLoading(false);
                    notify(false, "Minted Successfully, Now check on opensea");
                });
        } catch (e) {
            setIsLoading(false);
            notify(true, "something went wrong,pls try again");
        }

    }

    useEffect(() => {
        if (isActive) {
            getnftItemRecord();
        }
        // getStackerInfo();
    }, [isActive, account]);

    return (
        <div>
            <div className="first-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9 col-md-6 col-sm-12 col-xl-9">
                            <div className="logo-header">
                                <img src="images/header-logo.png" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xl-3">
                            {isActive ? (<div className="sr-btn-wrap">
                                <button type="button" onClick={() => disconnect()} className="sr-btn">
                                    {account.slice(0, 6)
                                                .concat(`...${account.slice(-4)}`)}
                                                </button>
                            </div>) : (<div className="sr-btn-wrap">
                                <button type="button" onClick={() => connect()} className="sr-btn">Connect wallet</button>
                            </div>)}

                        </div>
                    </div>
                </div>
            </div>
            <div className="first-slider-section">
                <div className="container-fluid">
                    <div className="row mt-3 pb-4">
                        <div className="col-lg-4 col-md-12 col-xl-4 col-sm-12">
                            <div className="img-section mt-3">
                                <div className="img-slider">
                                    <img src="images/NFT/UltraNFT/Provozori.gif" alt="" />
                                </div>
                                <h4>ONLY 1 item Available</h4>
                                <h1>PROVOZORI</h1>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 col-xl-8 col-sm-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                                    <div className="heading-ultra">
                                        <h1>ULTRA</h1>
                                        <h2>PRICE: {parseFloat(nftItemRecord.mintPrice) / 10 ** 18} ETH</h2>
                                        {parseInt(nftItemRecord.mintedCopies) === parseInt(nftItemRecord.totalCopies) ?
                                            (<div className="sr-btn-wrap">
                                                <button type="button" className="sr-btn">Sold</button>
                                            </div>) : (<div className="sr-btn-wrap">
                                                <button type="button" onClick={() => mint()} disabled={isLoading} className="sr-btn">{isLoading ? 'Loading..' : 'mint'}</button>
                                            </div>)}

                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-xl-8 col-sm-12">
                                    <div className="logo-icon">
                                        <img src="images/logo-icon.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="parties">
                                <h4>AVAILABLE RARITIES:</h4>
                                <h4><span style={{ color: '#a1a1a1' }}>Common</span> - Floor Price 0.04 eth - Total of 500 Pieces
                                </h4>
                                <h4><span style={{ color: '#74bde6' }}>Rare</span> - Floor Price 0.1 eth - Total of 300 Pieces</h4>
                                <h4><span style={{ color: '#d774e6' }}>Epic</span> - Floor Price 0.25 eth - Total of 150 Pieces</h4>
                                <h4><span style={{ color: '#ebdb29' }}>Legendary</span> - Floor Price 0.9eth - Total of 50 Pieces
                                </h4>
                                <h4><span>ULTRA</span> - Floor Price 10 eth - Total of 1 Piece ONLY!</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FirstSection;
